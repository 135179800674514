import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/layout'
import AboutUs from "../components/AboutUs/AboutUs"
import Breadcrumb from "../components/Global/Breadcrumb"
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'


function AboutUsTemplate(props) {
    console.log(props)
    let data = props.data;

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>
                    {/* <section>
                        <Introduction backImg={data.allWpPage.nodes[0].aboutPageAcf.introductionBackgroundImage} title={data.allWpPage.nodes[0].aboutPageAcf.introductionHeader} />
                    </section> */}

                    <section>
                        <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>

                    <div className="container-cs m-auto py-16 about-content-editor" dangerouslySetInnerHTML={{ __html: data.allWpPage.nodes[0].content }} />
                    <section style={{ backgroundColor: "#f7f4e6" }}>
                        <AboutUs
                            title={data.allWpPage.nodes[0].aboutPageAcf.aboutTitle}
                            paragraph={data.allWpPage.nodes[0].aboutPageAcf.aboutFirstParagraph}
                            subParagraph={data.allWpPage.nodes[0].aboutPageAcf.aboutSecondParagraph}
                            aboutImage={data.allWpPage.nodes[0].aboutPageAcf.aboutImage}
                            sectionName={data.allWpPage.nodes[0].aboutPageAcf.aboutSectionName}
                        />
                    </section>

                    <section>
                        <div className="flex flex-wrap container-cs mx-auto">
                            {(data.allWpPage.nodes[0].aboutPageAcf.coreValues && data.allWpPage.nodes[0].aboutPageAcf.coreValues.cores) && data.allWpPage.nodes[0].aboutPageAcf.coreValues.cores.map((item, i) => (
                                <div className="w-full md:w-1/3 px-4 ab-mn-tab" key={`core-val-${i}`}>
                                    <div className="mn-tab p-8">
                                        <div>
                                            <img
                                                src={item.icon.sourceUrl}
                                                alt={item.icon.altText}
                                                className="m-auto"
                                            />

                                        </div>
                                        <h3>{item.title}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>

                    <section>
                        <div className="py-16 md:py-20 container-cs mx-auto">
                            <h2>
                                {data.allWpPage.nodes[0].aboutPageAcf.title}
                            </h2>
                            <div className="py-8">
                                <LiteYouTubeEmbed
                                    id="8_5rncQ-_9A"
                                    title="Δίκτυο - Οι αποθήκες του Δικτύου | Makios Logistics"
                                />
                            </div>
                            <div className="w-1/3 m-auto">
                                <div className="mn-tab p-8">
                                    <div>
                                        <img
                                            src={data.allWpPage.nodes[0].aboutPageAcf.katastatiko.icon.sourceUrl}
                                            alt={data.allWpPage.nodes[0].aboutPageAcf.katastatiko.icon.altText}
                                            className="m-auto"
                                        />

                                    </div>
                                    <h3>{data.allWpPage.nodes[0].aboutPageAcf.katastatiko.title}</h3>
                                    <div className="w-full flex justify-center">
                                        <a className="text-center" href={data.allWpPage.nodes[0].aboutPageAcf.katastatiko.url} target={"_blank"}>{data.allWpPage.nodes[0].aboutPageAcf.katastatiko.subtitle}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>
            </Layout>
        </div>
    );
}

export default AboutUsTemplate


export const pageQuery = graphql`query GET_ABOUT_US($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    content
    breadcrumbAcf {
         breadcrumbName
       }
      aboutPageAcf {
        aboutTitle
        aboutFirstParagraph
        aboutSectionName
        aboutSecondParagraph {
          icon {
            altText
            sourceUrl
            
          }
          description
        }

        coreValues{
            cores{
              title
              text
              icon{
                sourceUrl
                altText
                
              }
            }
          }
          title
          video
          katastatiko{
            title
            subtitle
            icon{
                sourceUrl
                altText
                
            }
            url
          }
       }
    }

    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`
